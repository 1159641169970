<template>
  <div v-if="isSecondary">
    <a id="contact-wa-btn" href="javascript:" @click="handleContact" style="text-decoration: none">
      <div class="btn btn--whatsapp action--button">
        <img src="@/assets/img/icons/whatsapp-1.svg" alt="Whatsapp" />
        <span>{{ $t('general.contactWa') }}</span>
      </div>
    </a>
    <a id="contact-me-btn" href="javascript:" @click="handleEnquiry" style="text-decoration: none">
      <div class="btn btn-primary action--button">
        <img src="@/assets/img/icons/phone-2.svg" alt="Phone" />
        <span>{{ $t('general.contactMe') }}</span>
      </div>
    </a>
  </div>
  <div v-else>
    <a id="contact-us-btn" href="javascript:" @click="handleContact" style="text-decoration: none">
      <div class="btn btn--whatsapp action--button">
        <img src="@/assets/img/icons/whatsapp-1.svg" alt="Whatsapp" />
        <span>{{ $t('general.contactUs') }}</span>
      </div>
    </a>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'contact-button',
  props: ['type', 'name', 'agentUuid', 'price', 'listingUuid', 'contactNumber', 'ownerName'],
  mixins: [HelperMixin],
  data: () => ({
    windowReference: null,
  }),
  computed: {
    isSecondary() {
      const category = this.$route.params.category;
      const splitCategory = category ? category.split('-') : null;
      return (
        splitCategory &&
        (splitCategory[0] === 'properti' || splitCategory[splitCategory.length - 1] === 'secondary')
      );
    },
  },
  methods: {
    async handleEnquiry() {
      this.$modal.show('modal--contact-us', {
        listingUuid: this.listingUuid,
      });
    },
    handleContact() {
      this.windowReference = window.open();
      this.handleContactAction();
    },
    async handleContactAction() {
      let url = '';
      const contactNumber = this.contactNumber
        ? this.contactNumber
        : '+' + this.$t('general.phone1');
      if (this.contactNumber == null) {
        // TIPE LISTING PRIMARY
        let textMessage = '';
        if (this.type === 'unitType') {
          textMessage +=
            'Halo tim BeliRumah! Saya ingin mendapat informasi lebih mengenai tipe unit ini ';
        } else if (this.type === 'unit') {
          textMessage +=
            'Halo tim BeliRumah! Saya ingin mendapat informasi lebih mengenai unit ' +
            this.name +
            ' ';
        } else {
          textMessage +=
            'Halo tim BeliRumah! Saya ingin mendapat informasi lebih mengenai proyek ini ';
        }

        const { data } = await this.$http.post('/api/lead/create', {
          agent_uuid: this.agentUuid,
          listing_uuid: this.listingUuid,
          source: 'website',
        });
        textMessage +=
          this.$store.state.global.baseUrl + this.$route.path + `?lead_uuid=${data.data.lead_uuid}`;
        url = `https://api.whatsapp.com/send?phone=${contactNumber}&text=${textMessage}`;
      } else {
        // TIPE LISTING SECONDARY

        // eslint-disable-next-line no-unused-vars
        const { data } = await this.$http.post('/api/lead/create', {
          agent_uuid: this.agentUuid,
          listing_uuid: this.listingUuid,
          source: 'website',
        });
        let textMessage = '';
        textMessage += `Halo ${this.ownerName}! Saya ingin mendapat informasi lebih mengenai properti ini `;
        textMessage += this.$store.state.global.baseUrl + this.$route.path;
        url = `https://api.whatsapp.com/send?phone=${contactNumber}&text=${textMessage}`;
      }

      if (this.windowReference) {
        this.windowReference.location = url;
      }
      // Not working in safari (window.open in async function)
      // window.open(url, '_blank');
    },
  },
};
</script>
